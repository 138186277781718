body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout{
  width: 85%;
  margin:0 auto;
}

.header{
  display: flex;
  height: 132px;
  display: flex;
  border-bottom:3px solid black;
  margin-bottom: 35px;
}

.header .logo{
  flex-grow: 1;
  display: flex;
}

.header .logo h2{
  display: block;
  flex-basis: 40px;
  margin-left: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.header .logo span{
  display: block;
  font-size: 114px;
  font-weight: bolder;
  margin-top:-9px;
}

.header nav
{
  flex-grow: 1;
} 

.header nav ul{
  height: 132px;
  display: flex; /*para que uno se ponga al lado del otro*/
  justify-content: flex-end;
  align-items: center;
  list-style: none;

}

.header li{
  text-transform: uppercase;/**para q este todo en mayuscula*/
  font-weight: bold;
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
}

.header li a{
  color: black ; /*esto es lo mismo que el navlink*/
  text-decoration: none;
  transition: all 300ms;
}

.header a:hover{
 color:#fa4529
}

.header .active{
  color:#fa4529
}



.footer{
  text-align: center;
  font-size: 14px;
  margin-top: 80px;
  border-top: 3px solid black;
  padding-top: 45px;
  background: #f5f5f5;
  min-height: 50px;
  line-height: 16px;
}

.content{
  min-height: 400px;
}

.home h1{
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 200;
  font-size: 30px; 
  margin-bottom: 0px;
}

.home strong{
  color:#fa4529;
}

.home h2{
  color:rgb(90, 87, 87);
  font-weight: lighter;
}

.home .title a{
  padding: 6px;
  border-radius: 5px;
  color:white;
  background: #fa4529;
  text-decoration: none;
  transition: all 300ms;
}

.home .title a:hover{
  background-color: green;
}

.page .heading,
.home .heading{
  font-size: 35spx;
  font-weight: 500;
  color:black;
  font-style: italic;
  margin-bottom: 0px;
}
.page .heading{
  font-size: 55px;
  font-weight: bold;
}

.home p{
  color:rgb(90, 87, 87);
}

input[type="text"],
textarea{
  display: block; /*para que cada uno ocupe una linea*/
  padding: 10px;
  width: 250px;
  margin-top: 20px;
}
input[type="submit"],button{
  padding: 15px;
  font-size: 15px;
  border-radius: 5px;
  color:white;
  background: #fa4529;
  text-decoration: none;
  transition: all 300ms;
  width: 250px;
  margin-top: 15px;
  cursor: pointer;
}

input[type="submit"]:hover{
  background: green;
}

.works{
  display: flex;
  flex-wrap: wrap;
}

.work-item{ 
  margin: 30px;
  width: 500px;
  flex-grow: 1;

}

.work-item img{
  width: 100%;
  height: 100%;
  transition: all 500ms;
}

.work-item .mask:hover img{
  width: 120%;
  height: 120%;
}

.work-item .mask{
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow: hidden; /*para que la imagen no se salga de su altura*/
  border: 1px solid #ccc;
  box-shadow: 0px 5px 15px #ddd;
}



.work-item span{
  display: block;
  color: gray;
  margin-top: 15px;
}

.work-item h2{
  font-size: 25px;
  color:black;
  display: block ;
  margin: 15px;
}

.work-item a{
  color: black;
  text-decoration: none;
  transition: all 300ms;
}

.work-item a:hover{
  color: #fa4529;
}

.work-item h3{
  display: block;
  margin-top: 0px;
  color: #444;
}
.page-work{
  text-align: center;
}

.page-work img{
  height: 100%;
  width:  100%;;
}

.page-work a{
  font-size: 25px;
}

.page-work .mask{
  height: auto;
  width: auto;
  overflow: hidden;
  box-shadow: 0px 0px 25px #ccc;
}

.InicioList{
  width: 100%;
 display: block;
 justify-content: center;
  text-align: center;
}

